import React, { useContext } from "react";
import { GlobalStateContext } from "../../../engine/GlobalState";
import { TextareaWrapper } from "./style";

const ChatInputComponent = (props) => {
  const context = useContext(GlobalStateContext);

  const submitHandle = (e) => {
    e.preventDefault();

    context.sendMessage(context.userInput.value);

    context.userInput.set("");
  };

  const onRead = () => {
    let { api, updates, history } = context;
    if (!(updates.value || []).length) return;

    history.set((ph) => ph.concat(updates.value));
    updates.set([]);

    api
      .markAsRead()
      .then((result) => {
        // console.log('Result of update was marked as read: ', result)
      })
      .catch(() => {});
  };

  const onChangeInput = (value) => {
    context.updates.value.length !== 0 && onRead();
    context.userInput.set(value);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13 && !e.shiftKey && !e.ctrlKey) {
      e.preventDefault();
      submitHandle(e);
      return (e.target.value = "");
    } else if (e.keyCode === 13 && e.ctrlKey && !e.shiftKey) {
      const caret = e.target.selectionStart;
      return e.target.setRangeText("\n", caret, caret, "end");
    }
  };
  return (
    <div className="ACCW_FooterComponent_chat_input">
      <form onSubmit={submitHandle} className="ACCW_ChatInputComponent_form">
        {context.replies.value.length && context.lockInput.value ? (
          <div
            className={`ACCW_ChatInputComponent_input ${
              context.styleGradient.value
                ? "accw_input_gradient"
                : "accw_input_gradient__FALSE"
            } ACCW_ChatInputComponent_input_locked`}
          >
            {context.style.value.lockText
              ? context.style.value.lockText
              : "Please choose an option above"}
          </div>
        ) : (
          <TextareaWrapper
            type="text"
            onKeyDown={handleKeyDown}
            onChange={(e) => onChangeInput(e.target.value)}
            value={context.userInput.value}
            onFocus={onRead}
            placeholder="Schreibe deine Nachricht..."
            placeholderColor={context.style.value.textColor}
            style={{
              resize: "none",
              color: context.style.value.textColor,
              fontSize: context.style.value.messageFontSize,
              backgroundColor: "transparent",
              paddingLeft: "10px",
            }}
            className={`ACCW_ChatInputComponent_input ${
              context.styleGradient.value && "accw_input_gradient"
            }`}
          />
        )}

        <button type="submit" style={{ display: "none" }} />
      </form>
    </div>
  );
};

export default ChatInputComponent;
